<template>
  <div id="app">
    <vue-headful v-if="$route.name" :title="title" :description="description" />
    <CookieBanner />
    <component v-bind:is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout';
import RenovaDashboardLayout from '@/layouts/RenovaDashboardLayout';
import WhiteBlancLayout from '@/layouts/WhiteBlancLayout';
import LandingLayout from '@/layouts/LandingLayout';
import { GET_PHONE_CODES } from '@/store/actions/dictionary';
import CookieBanner from '@/components/CookieBanner';
import HrRenovaLayout from '@/layouts/HrRenovaLayout';
import { USER_SAVE } from '@/store/actions/user';
import locale from 'element-ui/lib/locale';
import i18n from '@/i18n';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import dayjs from 'dayjs';
import { mapActions } from 'vuex';

export default {
  name: 'app',
  components: {
    AdminLayout,
    WhiteBlancLayout,
    LandingLayout,
    RenovaDashboardLayout,
    HrRenovaLayout,
    CookieBanner,
  },
  created() {
    if (window.adminLayout === 'RenovaDashboardLayout') {
      import('@/assets/scss/renova/colors.scss');
    }
    this.$store.dispatch(GET_PHONE_CODES);
    this.logoutIfTokenExpired();
  },
  mounted() {
    this.setDefaultLang();
    this.setFavicon();
  },
  computed: {
    title() {
      const title = this.$t(`meta.${this.$route.name}.title`);
      // eslint-disable-next-line no-template-curly-in-string
      return title.replaceAll('${window.companyName}', window.companyName);
    },
    description() {
      const description = this.$t(`meta.${this.$route.name}.description`);
      // eslint-disable-next-line no-template-curly-in-string
      return description.replaceAll('${window.companyName}', window.companyName);
    },
    layout() {
      if (this.$route.meta.layout === 'LandingLayout') {
        document.getElementsByTagName('body')[0].classList.add('l-body');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('l-body');
      }
      return this.$route.meta.layout || WhiteBlancLayout;
    },
  },
  methods: {
    setDefaultLang() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const langFromQueryParam = urlSearchParams.get('lang');
      if (langFromQueryParam && ['ru', 'en', 'es', 'pt'].includes(langFromQueryParam) && langFromQueryParam !== localStorage.getItem('lang')) {
        this.changeLang(langFromQueryParam);
      }
    },
    setFavicon() {
      if (window.adminLayout === 'RenovaDashboardLayout') {
        const favicon = document.getElementById('favicon');
        favicon.href = `/favicons/favicon_${window.whiteLabelCompany}.svg`;
      }
    },
    changeLang(lang) {
      localStorage.setItem('lang', lang);
      locale.use(this.$elementLangs[i18n.locale]);
      if (this.user?.id) {
        this.$store.dispatch(USER_SAVE, {
          id: this.user.id,
          defaultLocale: lang,
        }).then(() => {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    },
    logoutIfTokenExpired() {
      const tokenExpired = localStorage.getItem('tokenExpired');

      if (tokenExpired) {
        const currentDateTime = dayjs();
        const tokenExpiredDateTime = dayjs(tokenExpired);
        if (currentDateTime.isAfter(tokenExpiredDateTime)) {
          this.$store.dispatch(AUTH_LOGOUT)
            .then(() => {
              this.$router.replace('/login');
            });
          localStorage.removeItem('tokenExpired');
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
