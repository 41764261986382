<template>
  <div class="l-top-bar">
    <div :class="{ 'l-top-bar__fixed': drawer }" class="l-top-bar__inner container">
      <div class="l-top-bar__logo-block">
        <img
          class="l-top-bar__logo cp"
          :src="`/img/Life-logo.svg`"
          alt="logo"
          @click="$router.push('landingMain')"
        />
      </div>

      <div class="l-top-bar__links">
        <el-dropdown trigger="click">
          <span :class="dropDownActiveStyleFirst" class="el-dropdown-link text_16 text_dark cp">
            {{ $t('topBar.forPrivateBusinesses') }}<i class="el-icon-arrow-down el-icon--right" />
          </span>

          <el-dropdown-menu slot="dropdown">
            <router-link class="l-top-bar__dropdown-link" to="/" exact>
              <el-dropdown-item>
                {{ $t('topBar.main') }}
              </el-dropdown-item>
            </router-link>

            <router-link class="l-top-bar__dropdown-link" to="/health-insurance">
              <el-dropdown-item>
                {{ $t('topBar.needIns') }}
              </el-dropdown-item>
            </router-link>

            <router-link class="l-top-bar__dropdown-link" to="/switch-health-insurance">
              <el-dropdown-item>
                {{ $t('topBar.haveIns') }}
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="ml_24" trigger="click">
          <span :class="dropDownActiveStyleSecond" class="el-dropdown-link text_16 text_dark cp">
            {{ $t('topBar.forInsuranceCompanies') }}<i class="el-icon-arrow-down el-icon--right" />
          </span>

          <el-dropdown-menu slot="dropdown">
            <router-link class="l-top-bar__dropdown-link" to="/acquisition-insurance" exact>
              <el-dropdown-item>
                {{ $t('topBar.becomePartner') }}
              </el-dropdown-item>
            </router-link>

            <router-link class="l-top-bar__dropdown-link" to="/insurance-platform">
              <el-dropdown-item>
                {{ $t('topBar.automationPlatform') }}
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="l-top-bar__links2">
        <div class="l-top-bar__lang-block l-top-bar__lang-block-dt cp">
          <el-dropdown :hide-timeout="0" :show-timeout="0" trigger="click" @command="changeLang">
            <span class="el-dropdown-link flex flex-ac">
              <img src="/img/misc/language.svg" alt="" />

              {{ languageFilter[currentLang] ? languageFilter[currentLang].title : '' }}

              <img class="ml_4" src="/img/misc/arrow-sm.svg" alt="" />
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :class="{ text_blue: currentLang === item.id }"
                :command="item.id"
                v-for="item in languageFilter"
                :key="item.id"
              >
                {{ item.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-dropdown
          class="mr_32"
          :hide-timeout="0"
          :show-timeout="0"
          trigger="click"
          placement="bottom"
          @command="goToLoginPage"
        >
          <span class="text_16 text_dark cp">
            {{ $t('topBar.login') }}
          </span>

          <el-dropdown-menu class="el-popper__login" slot="dropdown">
            <el-dropdown-item :command="item" v-for="item in loginOptions" :key="item">
              <span class="text_16">{{ $t(`topBar.${item}`) }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button
          class="el-button--dark el-button--r-8"
          @click="getDemo"
          v-if="$route.name === 'insurancePlatform'"
          >{{ $t('topBar.needDemo') }}
        </el-button>
      </div>

      <div class="l-top-bar__mobile-menu-trigger" @click="drawer = !drawer">
        <img :src="mobileTriggerIcon" alt="" />
      </div>
    </div>

    <el-drawer
      :append-to-body="true"
      :visible.sync="drawer"
      :show-close="false"
      :withHeader="false"
      size="100%"
      direction="ttb"
    >
      <div class="l-top-bar__mobile-menu" @click="drawer = !drawer">
        <router-link class="text_16 text_dark" to="/" exact>
          {{ $t('topBar.main') }}
        </router-link>

        <router-link class="text_16 text_dark" to="/health-insurance">
          {{ $t('topBar.needIns') }}
        </router-link>

        <router-link class="text_16 text_dark" to="/switch-health-insurance">
          {{ $t('topBar.haveIns') }}
        </router-link>

        <router-link class="text_16 text_dark" to="/acquisition-insurance">
          {{ $t('topBar.becomePartner') }}
        </router-link>

        <router-link class="text_16 text_dark" to="/insurance-platform">
          {{ $t('topBar.automationPlatform') }}
        </router-link>

        <router-link class="text_16 text_dark" to="/hr/login">
          {{ $t('topBar.loginHr') }}
        </router-link>
      </div>

      <div class="l-top-bar__lang-block cp">
        <el-dropdown :hide-timeout="0" :show-timeout="0" trigger="click" @command="changeLang">
          <span class="el-dropdown-link flex flex-ac">
            <img src="/img/misc/language.svg" alt="" />

            {{ languageFilter[currentLang].title }}

            <img class="ml_4" src="/img/misc/arrow-sm.svg" alt="" />
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :class="{ text_blue: currentLang === item.id }"
              :command="item.id"
              v-for="item in languageFilter"
              :key="item.id"
            >
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import i18n from '@/i18n';
import locale from 'element-ui/lib/locale';
import { AUTH_REQUEST } from '@/store/actions/auth';
import { SET_PHONE_CODE } from '@/store/actions/dictionary';

export default {
  name: 'landing-top-bar',
  data() {
    return {
      currentLang: null,
      lang: {
        ru: {
          id: 'ru',
          title: 'RU',
        },
        en: {
          id: 'en',
          title: 'EN',
        },
        es: {
          id: 'es',
          title: 'ES',
        },
        // pt: {
        //   id: 'pt',
        //   title: 'PT',
        // },
      },
      loginOptions: ['loginHr', 'loginAdminIC'],
      shortLang: {
        ru: {
          id: 'ru',
          title: 'RU',
        },
        en: {
          id: 'en',
          title: 'EN',
        },
        es: {
          id: 'es',
          title: 'ES',
        },
        // pt: {
        //   id: 'pt',
        //   title: 'PT',
        // },
      },
      drawer: false,
    };
  },
  created() {
    this.currentLang = localStorage.getItem('lang');

    if (this.$route.name !== 'insurancePlatform' && i18n.locale === 'fr') {
      this.currentLang = 'en';
      i18n.locale = 'en';
      localStorage.setItem('lang', 'en');
    }

    this.setSelectedCountry();
  },
  watch: {
    $route(to, from) {
      if (this.$route.name !== 'insurancePlatform' && i18n.locale === 'fr') {
        this.currentLang = 'en';
        i18n.locale = 'en';
      }

      this.setSelectedCountry();
    },
  },
  computed: {
    languageFilter() {
      return this.$route.name === 'insurancePlatform' ? this.lang : this.shortLang;
    },
    mobileTriggerIcon() {
      return !this.drawer ? '/img/landings/misc/d-i.svg' : '/img/landings/misc/d-i-x.svg';
    },
    dropDownActiveStyleFirst() {
      return {
        text_blue: ['landingMain', 'healthInsurance', 'switchHealthInsurance'].some(
          (item) => item === this.$route.name,
        ),
      };
    },
    dropDownActiveStyleSecond() {
      return {
        text_blue: ['acquisitionInsurance', 'insurancePlatform'].some(
          (item) => item === this.$route.name,
        ),
      };
    },
  },
  methods: {
    changeLang(lang) {
      this.currentLang = lang;
      i18n.locale = this.currentLang;
      localStorage.setItem('lang', this.currentLang);
      locale.use(this.$elementLangs[i18n.locale]);
      this.setSelectedCountry();
    },
    getDemo() {
      localStorage.setItem('demo', true);

      this.$store
        .dispatch(AUTH_REQUEST, {
          name: 'demo@checkme.tech',
          password: '1234',
        })
        .then(() => {
          localStorage.setItem('demo', true);
          this.$router.push('/admin/tickets');
        });
    },
    goToLoginPage(page) {
      if (page === 'loginHr') {
        window.open(`/hr/login?lang=${this.currentLang}`);
      } else {
        window.open(`/login?lang=${this.currentLang}`);
      }
    },
    setSelectedCountry() {
      const ru = {
        code: '%2B7',
        flagIcon: 'https://350809.selcdn.ru/carefy-images/flag/mob_ru.png',
        flagIconWeb: 'https://350809.selcdn.ru/carefy-images/flag/web_ru.svg',
        id: '',
        name: 'Russia',
        phoneCode: '+7',
        phoneLength: 10,
        phoneMobileMask: '(###) ###-##-##',
        phoneRegion: 'RU',
      };

      const mx = {
        code: '%2B52',
        flagIcon: 'https://350809.selcdn.ru/carefy-images/flag/mob_mx.png',
        flagIconWeb: 'https://350809.selcdn.ru/carefy-images/flag/web_mx.svg',
        id: '',
        name: 'Mexico',
        phoneCode: '+52',
        phoneLength: 10,
        phoneMobileMask: '##-####-####',
        phoneRegion: 'MX',
      };

      if (i18n.locale === 'ru') {
        this.$store.commit(SET_PHONE_CODE, ru);
      } else {
        this.$store.commit(SET_PHONE_CODE, mx);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.l-top-bar__fixed {
  height: 56px;
  position: fixed;
  width: 100%;
  top: 0;
}
</style>
