<template>
  <div class="side-menu">
    <div class="side-menu__inner">
      <img class="side-menu__logo cp" :src="`/img/Life-logo-min.svg`" alt="" @click="$router.push('/')" />

      <router-link :to="item.path" v-for="(item, ind) in routes" :key="ind" class="side-menu__item">
        <img class="mb_4" :src="checkActive(item)" alt="">

        <p class="text_white text_10">{{ $t(`adminTitles.${item.name}`) }}</p>
      </router-link>

      <div class="side-menu__footer">
        <router-link v-if="user.email === 'demo@checkme.tech'" to="/admin/help" class="side-menu__item">
          <img class="mb_4" src="/img/menu/help.svg" alt="">

          <p class="text_white text_10">
            {{ $t('adminTitles.help') }}
          </p>
        </router-link>

        <router-link to="/admin/settings" class="side-menu__item cp">
          <img src="/img/menu/settings.svg" alt="">
          <p class="text_10 text_white">
            {{ $t('adminTitles.settings') }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
  name: 'side-bar-left',
  data() {
    return {
      routes: [],
    };
  },
  created() {
    this.routes = this.$router.options.routes.filter((item) => {
      const menuAvailable = item.meta && item.meta.menu;
      let routeAvailableByRole = true;
      if (item.meta && item.meta.roles) {
        routeAvailableByRole = item.meta.roles.includes(this.userRole);
      }
      return menuAvailable && routeAvailableByRole;
    }).map((item) => item.meta);
  },
  computed: {
    ...mapGetters(['ticketsFiltersData', 'user', 'userRole']),
  },
  methods: {
    checkActive(item) {
      return item.name === 'tickets' && this.ticketsFiltersData.haveTodoTicket ? item.menuImgActive : item.menuImg;
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style scoped></style>
