import axios from 'axios';

const defaultDoctorsFilters = {
  searchQuery: '',
  specId: null,
  status: null,
};
const defaultSpecsFilters = {
  searchQuery: '',
};
const state = {
  doctors: [],
  doctorDetails: null,
  doctorSlots: null,
  specs: null,
  doctorsFilters: { ...defaultDoctorsFilters },
  specsFilters: { ...defaultDoctorsFilters },
};

const getters = {
  telemedDoctors: (state) => state.doctors,
  telemedSpecs: (state) => state.specs,
  telemedDoctorDetails: (state) => state.doctorDetails,
  telemedDoctorSlots: (state) => state.doctorSlots,
  doctorsFilters: (state) => state.doctorsFilters,
  specsFilters: (state) => state.specsFilters,
};

export function getDoctors(filters = {}) {
  return axios.post('/insurance/telemedicine/doctor/list', {
    ...filters,
  });
}

export function getDoctorSlots(doctorId) {
  return axios.post('/insurance/telemedicine/slot/list', { doctorId });
}

export function getSpecs(filters = {}) {
  return axios.post('/insurance/telemedicine/doctor-specialization/list', {
    ...filters,
    status: 'ACTIVE',
  });
}

const actions = {
  fetchTelemedDoctors({ commit, state }) {
    getDoctors(state.doctorsFilters)
      .then((response) => {
        commit('setTelemedDoctors', response.data);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  },
  fetchTelemedSpecs({ commit, state }) {
    getSpecs()
      .then((response) => {
        commit('setTelemedSpecs', response.data);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  },
  addTelemedSlots({ dispatch }, data) {
    axios
      .post('/insurance/telemedicine/slot/save', {
        doctorId: data.doctorId,
        ...data.slot,
      })
      .then(() => {
        dispatch('fetchTelemedDoctorSlots', data.doctorId);
      });
  },
  deleteTelemedSlots({ dispatch }, data) {
    axios
      .post('/insurance/telemedicine/slot/delete', {
        doctorId: data.doctorId,
        ...data.slot,
      })
      .then(() => {
        dispatch('fetchTelemedDoctorSlots', data.doctorId);
      });
  },
  fetchTelemedDoctorDetails({ commit, dispatch }, doctorId) {
    axios.post('/insurance/telemedicine/doctor/detail', { doctorId }).then((response) => {
      commit('setTelemedDoctorDetails', response.data);
    });
  },
  fetchTelemedDoctorSlots({ commit, dispatch }, doctorId) {
    getDoctorSlots(doctorId).then((response) => {
      commit('setTelemedDoctorSlots', response.data);
    });
  },

  saveTelemedDoctor({ commit, dispatch }, body) {
    axios
      .post('/insurance/telemedicine/doctor/save', {
        ...body,
      })
      .then((response) => {
        dispatch('fetchTelemedDoctors');
        if (body.doctorId) {
          dispatch('fetchTelemedDoctorDetails', body.doctorId);
        }
      });
  },
  saveTelemedSpec({ commit, dispatch }, body) {
    axios
      .post('/insurance/telemedicine/doctor-specialization/save', {
        ...body,
      })
      .then((response) => {
        dispatch('fetchTelemedSpecs');
      });
  },
  deleteTelemedSpec({ commit, dispatch }, specializationId) {
    axios
      .post('/insurance/telemedicine/doctor-specialization/save', {
        specializationId,
        status: 'NOT_ACTIVE',
      })
      .then((response) => {
        dispatch('fetchTelemedSpecs');
      });
  },
  updateTelemedDoctorsFilters({ commit, dispatch }, filters) {
    commit('setTelemedDoctorsFilters', filters);
    dispatch('fetchTelemedDoctors');
  },
  updateTelemedSpecsFilters({ commit, dispatch }, filters) {
    commit('setTelemedSpecsFilters', filters);
  },
  clearTelemedFilters({ commit }) {
    commit('clearAllTelemedFilters');
  },
  clearTelemedDoctor({ commit }) {
    commit('clearTelemedDoctor');
  },
};

const mutations = {
  setTelemedDoctors(state, doctors) {
    state.doctors = doctors.items;
  },
  setTelemedSpecs(state, specs) {
    state.specs = specs.items;
  },

  setTelemedDoctorDetails(state, details) {
    state.doctorDetails = details;
  },
  setTelemedDoctorSlots(state, slots) {
    state.doctorSlots = slots.items;
  },
  setTelemedDoctorsFilters(state, filters) {
    state.doctorsFilters = { ...state.doctorsFilters, ...filters };
  },
  setTelemedSpecsFilters(state, filters) {
    state.specsFilters = { ...state.specsFilters, ...filters };
  },
  clearAllTelemedFilters(state) {
    state.doctorsFilters = { ...defaultDoctorsFilters };
  },
  clearTelemedDoctor(state) {
    state.doctorDetails = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
